import { NextPage } from "next";
import styles from "src/styles/Index.module.css";
import { DEFAULT_DOC_TITLE } from "src/constants";
import { NextSeo } from "next-seo";

const Home: NextPage = () => {
  return (
    <div className={styles.container}>
      <NextSeo noindex nofollow />

      <h1 className="text-3xl font-bold underline">{DEFAULT_DOC_TITLE}</h1>
    </div>
  );
};

export default Home;
